import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "apollo-link-context";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { getToken } from "./authService";
import { ERROR_TYPES } from "../../utils/CONSTANTS";
import {
  getGraphQlUri,
  getSubscriptionKey,
} from "../../appConfig/configService";

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const customGraphQlUri = () => {
  const graphQlApiUri = getGraphQlUri();
  console.log("Graphql Uri from Json Config Data:", graphQlApiUri);
  return graphQlApiUri;
};

const customHttpLink = new HttpLink({ uri: customGraphQlUri });
const httpLink = link.concat(customHttpLink);

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // todo: switch for taking care different errors coming from graphql. Will be used for error handling covered in different US.
      switch (err.code) {
        case ERROR_TYPES.UNAUTHENTICATED:
          // if statement to solve system going in loop when unauthenticated error is thrown..no refresh needed on local host. Dev code
          if (!window.location.href.includes("localhost"))
            window.location.reload();
          break;
        default:
          console.log("Error connecting to graphql endpoint");
      }
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      AttributesType: {
        fields: {
          attributeValues: {
            read(_, { readField }) {
              const attributesArray = readField("attributes");
              return (attributesArray || []).reduce(
                (result, { attributeName, attributeValue }) => ({
                  ...result,
                  [attributeName]: attributeValue,
                }),
                {}
              );
            },
          },
        },
      },
      ListItemsType: {
        fields: {
          label: {
            read(_, { readField }) {
              return readField("description");
            },
          },
          value: {
            read(_, { readField }) {
              return readField("code");
            },
          },
        },
      },
      BaseListTypes: {
        keyFields: ["listName"],
      },
      ListByNameType: {
        keyFields: ["listName"],
      },
      BaseQuestionType: {
        keyFields: ["caseId"],
      },
      UrepagesType: {
        keyFields: ["code"],
        fields: {
          visible: {
            read(_, { readField }) {
              const sections = readField("sections");
              return (
                Array.isArray(sections) &&
                sections.some((section) => readField("visible", section))
              );
            },
          },
        },
      },
      SectionsType: {
        keyFields: ["code"],
        fields: {
          visible: {
            read(_, { readField }) {
              const bqs = readField("baseQuestions");
              return (
                Array.isArray(bqs) && bqs.some((bq) => readField("visible", bq))
              );
            },
          },
        },
      },
      BaseQuestionsType: {
        /* for inner baseQuestions array in sections */
        keyFields: ["code"],
        fields: {
          questionText: {
            read(text) {
              return text.replace(/^#([a-zA-Z_=0-9<>&]*\/)*|\\n.*/gm, "");
            },
          },
          customProperties: {
            read(_, { readField }) {
              const properties = readField("properties");
              return (properties || []).reduce(
                (p, { propertyName, propertyValue }) => ({
                  ...p,
                  [propertyName]: propertyValue,
                }),
                {}
              );
            },
          },
          callbackFunctions: {
            read() {
              return [];
            },
          },
        },
      },
      ReflexiveQuestionType: {
        fields: {
          questionText: {
            read(text) {
              return text.replace(/^#([a-zA-Z_=0-9<>&]*\/)*|\\n.*/gm, "");
            },
          },
          listItems: {
            read(_, { readField }) {
              const options = readField("options") || [];
              return options.map((option) => ({
                label: option,
                value: option,
              }));
            },
          },
          customProperties: {
            read(_, { readField }) {
              const properties = readField("properties");
              return (properties || []).reduce(
                (p, { propertyName, propertyValue }) => ({
                  ...p,
                  [propertyName]: propertyValue,
                }),
                {}
              );
            },
          },
          callbackFunctions: {
            read() {
              return [];
            },
          },
        },
      },
      DisclosureType: {
        keyFields: ["aliasName", "baseQuestionCode"],
      },
    },
  }),
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});

export { useApolloNetworkStatus };
