import propTypes from "prop-types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import {
  GelCaption,
  GelButton,
  GelContainerLite,
  GelRowLayout,
  GelBoxLayout,
} from "@tal-gel/components";
import TalStepIcon from "./TalStepIcon";
import { getGelTokens } from "@tal-gel/theming";
import { useEffect, useState, useRef } from "react";
import { useQueryLazyPageStatus } from "../../utils/useQueryPageStatus";
import { useDispatch } from "react-redux";
import { loadPages } from "../../globalStore/pages-slice";
import { useNavigationButtonStatus, usePages } from "../../globalStore/hooks";
import { PAGE_STATUSES } from "../../utils/CONSTANTS";
import { ProgressBar } from "../UIUtilities/ProgressBar/ProgressBar.jsx";
import StickyComponent from "../UIUtilities/StickyComponent/StickyComponent";

const PREFIX = "TalStepper";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  instructions: `${PREFIX}-instructions`,
  stepLabelStyles: `${PREFIX}-stepLabelStyles`,
  caption: `${PREFIX}-caption`,
  captionActive: `${PREFIX}-captionActive`,
  sectionHeader: `${PREFIX}-sectionHeader`,
  leanSectionHeader: `${PREFIX}-leanSectionHeader`,
  sectionContent: `${PREFIX}-sectionContent`,
};

const StyledGelContainerLite = styled(GelContainerLite)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.button}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.stepLabelStyles}`]: {
    "& .MuiStepLabel-label, & .Mui-completed": {
      fontFamily: getGelTokens().global.fontfamilycondensed,
      fontSize: getGelTokens().global.fontsizebodydefault,
      fontWeight: getGelTokens().global.fontweightregular,
      color: getGelTokens().global.themeColorTextLight,
      "&.Mui-active": {
        fontWeight: getGelTokens().global.fontWeightBold,
        color: getGelTokens().global.themeColorTextDefault,
        "&.Mui-error": {
          color: "currentColor",
        },
      },
    },
    "& button.MuiButtonBase-root": {
      width: "100%",
      margin: "-24px -16px",
      padding: "24px 16px",
      boxSizing: "content-box",
    },
    "& .MuiStepConnector-alternativeLabel": {
      left: "calc(-50% + 30px)",
      right: "calc(50% + 41px)",
    },
  },

  [`& .${classes.caption}`]: {
    color: getGelTokens().global.themeColorTextLight,
  },

  [`& .${classes.captionActive}`]: {
    color: getGelTokens().global.themeColorTextDefault,
  },

  [`& .${classes.sectionHeader}`]: {
    paddingTop: "10px !important",
    paddingBottom: "10px",
    marginLeft: "70px",
    maxWidth: "1629px",
    marginRight: "70px",
  },
  [`& .${classes.leanSectionHeader}`]: {
    marginRight: "70px",
    marginLeft: "70px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  [`& .${classes.sectionContent}`]: {
    paddingBottom: "20px",
  },
}));

export default function TalStepperContainer({
  steps,
  getStepContent,
  handleContinueOnLast = () => {},
  handleBackOnFirst = () => {},
}) {
  const { pageStatusQuery, data, refetch, variables, loading } =
    useQueryLazyPageStatus();
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    pageStatusQuery();
  }, []);

  useEffect(() => {
    if (
      typeof refetch === "function" &&
      variables &&
      variables.pageStatusInput
    ) {
      refetch(variables);
    }
  }, [activeStep, variables]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data.pageStatus) &&
      data.pageStatus.length &&
      data.pageStatus[0].pages &&
      !loading
    ) {
      dispatch(loadPages(data.pageStatus[0].pages));
    }
  }, [data, dispatch, activeStep]);

  return (
    <TalStepper
      steps={steps}
      getStepContent={getStepContent}
      handleBackOnFirst={handleBackOnFirst}
      handleContinueOnLast={handleContinueOnLast}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  );
}

TalStepperContainer.propTypes = {
  steps: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      code: propTypes.string,
      caption: propTypes.string,
      isComplete: propTypes.bool,
      hasError: propTypes.bool,
    })
  ).isRequired,
  getStepContent: propTypes.func.isRequired,
  handleContinueOnLast: propTypes.func.isRequired,
  handleBackOnFirst: propTypes.func.isRequired,
};

function TalStepper({
  steps,
  getStepContent,
  handleContinueOnLast = () => {},
  handleBackOnFirst = () => {},
  activeStep,
  setActiveStep,
}) {
  const { pages } = usePages();
  const { disableContinueButton } = useNavigationButtonStatus();
  const sectionHeaderContainerRef = useRef(null);
  const [scroll, setScroll] = useState(false);
  let headerClassNames = classes.sectionHeader;
  headerClassNames += " section-header";

  let contentClassNames = classes.sectionContent;
  contentClassNames += " section-content";

  useEffect(() => {
    if (activeStep !== undefined && window && window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [activeStep]);

  const handleNavigation = (step) => {
    setActiveStep(step);
    return (
      sectionHeaderContainerRef?.current?.scrollIntoView &&
      sectionHeaderContainerRef?.current?.scrollIntoView(true)
    );
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1)
      return handleNavigation((prevActiveStep) => prevActiveStep + 1);

    handleContinueOnLast();
    return setActiveStep(0);
  };
  const handleBack = () => {
    if (activeStep > 0)
      return handleNavigation((prevActiveStep) => prevActiveStep - 1);

    handleBackOnFirst();
  };

  const onScrollCallback = (initialRefPosition) => {
    setScroll(window.scrollY > initialRefPosition);
  };

  return (
    <StyledGelContainerLite gutter="small" style={{ padding: "0px" }}>
      <GelRowLayout gutter="none">
        <StickyComponent
          onScrollCallback={onScrollCallback}
          ref={sectionHeaderContainerRef}
        >
          <div className={!scroll ? "section-header-container" : undefined}>
            <Stepper
              nonLinear
              alternativeLabel={false}
              activeStep={activeStep}
              className={scroll ? classes.leanSectionHeader : headerClassNames}
            >
              {steps.map(
                ({ label, caption, hasError, code, isComplete }, index) => {
                  const pageStatus =
                    pages &&
                    pages.length > 0 &&
                    pages.find(({ text }) => text === label);
                  return (
                    <Step
                      className={classes.stepLabelStyles}
                      completed={
                        (pageStatus && pageStatus.isPageComplete) || isComplete
                      }
                      key={label}
                    >
                      <StepButton
                        id={code}
                        data-testid={`stepper-${code}`}
                        onClick={() => setActiveStep(index)}
                        optional={
                          caption && (
                            <GelCaption
                              className={clsx(classes.caption, {
                                [classes.captionActive]: index === activeStep,
                              })}
                              condensed
                            >
                              {caption}
                            </GelCaption>
                          )
                        }
                      >
                        <StepLabel
                          error={
                            index !== activeStep &&
                            pageStatus &&
                            pageStatus.pageStatus === PAGE_STATUSES.Incomplete
                          }
                          completed={isComplete}
                          StepIconComponent={TalStepIcon}
                        >
                          {label}
                        </StepLabel>
                      </StepButton>
                    </Step>
                  );
                }
              )}
            </Stepper>
            <ProgressBar />
          </div>
        </StickyComponent>
      </GelRowLayout>
      {/* <GelRowLayout gutter="small">
        <ProgressBar />
      </GelRowLayout> */}
      <GelRowLayout gutter="small">
        {getStepContent({
          activeStepIndex: activeStep,
          activeStep: steps[activeStep],
        })}
        <div className={contentClassNames}>
          <GelBoxLayout space="auto" distribution="spaceBetween">
            <GelButton
              id="pageBackButton"
              secondary
              medium
              onClick={handleBack}
              data-testid="back-btn"
            >
              Back
            </GelButton>
            <GelButton
              id="pageContinueButton"
              primary
              medium
              onClick={handleNext}
              disabled={disableContinueButton}
              data-testid="continue-btn"
            >
              Continue
            </GelButton>
          </GelBoxLayout>
        </div>
      </GelRowLayout>
    </StyledGelContainerLite>
  );
}

TalStepper.propTypes = {
  steps: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      code: propTypes.string,
      caption: propTypes.string,
      isComplete: propTypes.bool,
      hasError: propTypes.bool,
    })
  ).isRequired,
  getStepContent: propTypes.func.isRequired,
  handleContinueOnLast: propTypes.func.isRequired,
  handleBackOnFirst: propTypes.func.isRequired,
  activeStep: propTypes.number,
  setActiveStep: propTypes.func,
};
